import React from 'react'
import Layout from '../components/Layout'
import { FormattedMessage } from "gatsby-plugin-intl"

const NotFoundPage = () => (
  <Layout>
    <div className="container heading-container">
      <h1>
        <FormattedMessage id="notfound.header" />
      </h1>
      <p>
        <FormattedMessage id="notfound.description" />
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
